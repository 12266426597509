<template>
  <ant-config-provider :locale="locale">
    <div id="container">

      <div class="header">
        <menu-outlined @click="navSwitch" />
        <div class="page-title">
          怪物猎人数据库（新地址：<a href="http://mh.arrowing.top" target="_blank">mh.arrowing.top</a>）
        </div>
        <span></span>
      </div>

      <ant-drawer
        title="导航"
        placement="left"
        :closable="true"
        v-model:visible="navShow"
      >
        <router-link 
          v-for="item of menu" 
          :key="item.name" 
          :to="'/' + item.name"
          :class="['nav-btn', {active: item._active}]">
          <ant-button @click="navShow = false">{{item.title}}</ant-button>
        </router-link>
      </ant-drawer>

      <div id="main">
        <router-view />
        <!-- 缓存会产生副作用，导致 router-view 挂载 2 次，原因不明 -->
        <!-- <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view> -->
      </div>

      <div class="copyright">
        Copyright © 2020 - 2023 <a href="">mh.arrowing.top</a>. All Rights Reserved.
      </div>

    </div>
  </ant-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import AntConfigProvider from 'ant-design-vue/lib/config-provider'
import AntButton from 'ant-design-vue/lib/button'
import AntDrawer from 'ant-design-vue/lib/drawer'
import MenuOutlined from '@ant-design/icons-vue/MenuOutlined'

export default {
  components: {
    AntDrawer,
    AntButton,
    MenuOutlined,
    AntConfigProvider
  },
  watch: {
    $route(to) {
      for (let item of this.menu) {
        item._active = to.meta.name === item.name
      }
    }
  },
  data() {
    return {
      locale: zhCN,
      navShow: false,
      menu: [
        {
          name: '',
          title: '首页',
        },
        {
          name: 'monster',
          title: '怪物'
        },
        {
          name: 'weapon',
          title: '武器'
        },
        {
          name: 'armor',
          title: '防具'
        },
        {
          name: 'quest',
          title: '任务'
        },
        {
          name: 'item',
          title: '道具'
        },
        {
          name: 'skill',
          title: '技能'
        },
        {
          name: 'maps',
          title: '地图'
        },
        {
          name: 'donate',
          title: '捐助'
        }
      ]
    }
  },
  methods: {
    navSwitch() {
      this.navShow = !this.navShow
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/index.scss';

#container{
  width: 100%;
  min-height: calc(100vh - 60px);
  margin: 0 auto;
  position: relative;

  .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    height: $headerHeight;
    line-height: $headerHeight;
    display: flex;
    background-color: #1890ff;
    justify-content: space-between;
    color: #fff;

    > span {
      width: $headerHeight;
      height: $headerHeight;
      line-height: $headerHeight;
      font-size: 20px;
    }
  }

  #main {
    padding: 50px;
  }

  #nav {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;

    > a {
      width: 25%;
      margin: 5px 3%;

      button {
        width: 100%;
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: #888;
    height: $footerHeight;
    line-height: $footerHeight;

    a {
      color: #888;
      display: inline;
      text-decoration: underline;
    }
  }
}

// 重写
// .ant-modal-mask {
//   z-index: 1111;
// }
// .ant-modal-wrap {
//   z-index: 1112;
// }

// 侧边导航
.ant-drawer {
  .nav-btn {
    width: 100%;
    margin-bottom: 15px;

    button {
      width: 100%;
    }

    &.active button{
      color: #fff;
      background-color: rgb(72,198,186);
      border-color: rgb(72,198,186);
      text-shadow: 0 -1px 0 rgba(0,0,0,.12);
      box-shadow: 0 2px 0 rgba(0,0,0,.045);
    }
  }
}

@include mobile {
  #container {
    width: 100%;

    #main {
      padding: 0;
      padding-bottom: $footerHeight;
    }
  }
}
</style>
