<template>
  <sub-list :columns-config="columns" :detail-config="detailColumns" />
</template>

<script>
import SubList from '../components/SubList.vue'

const columns = [
  {
    dataIndex: 'no',
    title: '#',
    width: 80,
    sorter: true,
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'eq',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus',
    fixed: 'left'
  },
  {
    dataIndex: 'name',
    title: '名称',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'part',
    title: '部位',
    slots: {
      customRender: 'armorPart'
    },
    filterMultiple: false,
    filters: [
      { text: '头部', value: 1 },
      { text: '肩部', value: 2 },
      { text: '腕部', value: 3 },
      { text: '腰部', value: 4 },
      { text: '腿部', value: 5 },
    ],
    customFilter: 'eq'
  },{
    dataIndex: 'type',
    title: '类型',
    slots: {
      customRender: 'armorType'
    },
    filters: [
      { text: '剑士', value: 1 },
      { text: '射手', value: 2 },
      { text: '不限', value: 0 }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'gender',
    title: '性别',
    slots: {
      customRender: 'armorGender'
    },
    filters: [
      { text: '男', value: 1 },
      { text: '女', value: 2 },
      { text: '不限', value: 0 }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'skills',
    title: '技能',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'armorSkills'
    },
    filterText: '如：属性攻击+3',
    customFilter: 'skill',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },  
  {
    dataIndex: 'rare',
    title: '稀有',
    width: 80,
    slots: {
      customRender: 'armorRare'
    },
    filterMultiple: false,
    filters: [
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10', value: 10 },
      { text: '11', value: 11 }
    ],
    customFilter: 'eq'
  },  
  {
    dataIndex: 'fire',
    title: '火',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'water',
    title: '水',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'thunder',
    title: '雷',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'ice',
    title: '冰',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'dragon',
    title: '龙',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'slot_num',
    title: '插槽',
    width: 100,
    sorter: true,
    slots: {
      customRender: 'slot'
    },
    filters: [
      { text: '3个', value: 3 },
      { text: '2个', value: 2 },
      { text: '1个', value: 1 },
      { text: '无', value: 0 }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'defense',
    title: '防御',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'maxDefense',
    title: '最大防御',
    width: 100,
    sorter: true
  },  
  {
    name: 'action',
    title: '操作',
    width: 60,
    slots: { customRender: 'action' },
    // fixed: 'right'
  }
]

// skills: {耐暑: 10, 耐寒: -10, 水属性攻击: 9}

const detailColumns = {
  total: {
    title: '套装信息',
    props: [
      {
        name: 'skills',
        title: '技能',
        filterName: 'armorSetSkillView'
      },
      {
        name: 'slot_num',
        title: '插槽'
      },
      {
        name: 'fire',
        title: '火'
      },
      {
        name: 'water',
        title: '水'
      },
      {
        name: 'thunder',
        title: '雷'
      },
      {
        name: 'ice',
        title: '冰'
      },
      {
        name: 'dragon',
        title: '龙'
      },
      {
        name: 'defense',
        title: '防御'
      },
      {
        name: 'maxDefense',
        title: '最大防御'
      }
    ]
  },
  armors: {
    title: '套装列表',
    props: [
      {
        name: 'part',
        title: '部位',
        filterName: 'armorPartView'
      },
      {
        name: 'name',
        title: '名称'
      },
      {
        name: 'skills',
        title: '技能',
        filterName: 'armorSkillView'
      },
      {
        name: 'slot_num',
        title: '插槽',
        filterName: 'slotView'
      },
      {
        name: 'fire',
        title: '火'
      },
      {
        name: 'water',
        title: '水'
      },
      {
        name: 'thunder',
        title: '雷'
      },
      {
        name: 'ice',
        title: '冰'
      },
      {
        name: 'dragon',
        title: '龙'
      },
      {
        name: 'defense',
        title: '防御'
      },
      {
        name: 'maxDefense',
        title: '最大防御'
      }
    ]
  }
}

export default {
  components: {
    SubList
  },
  data: () => {
    return {
      columns,
      detailColumns
    }
  }
}
</script>
