<template>
  <div :class="['sub-nav', {loading: !navItems}]">

    <ant-spin :spinning="!navItems || loading" :delay="100" tip="加载中...">

      <!-- 列表 -->
      <ant-card
        v-if="navItems"
        :title="$route.meta.title" 
        @click="cardClick($event)"
        :bodyStyle="{display: filterNoData && 'none'}">
        <template v-slot:extra>

          <ant-input-search 
            v-if="hasFilter"
            v-model:value="keyword"
            placeholder="输入名称"
            enter-button
            allowClear
            @search="onSearch">

            <template v-slot:addonBefore>
              {{filterItems ? filterItems.length : navItems.length}} 条
            </template>
          </ant-input-search>
        </template>
        
        <ant-card-grid
          v-show="isListAll || i < lessShow"
          v-for="(item, i) in (filterItems || navItems)"
          v-bind:key="item.path">
          <router-link :to="item.path">
            <img v-if="item.icon" class="icon" :src="item.icon" :alt="item.title" />
            {{item.title}}
          </router-link>
        </ant-card-grid>
        <ant-card-grid v-show="(filterItems || navItems).length > lessShow && !isListAll">
          <a class="expand-all" @click.stop="expandAll">展开剩余 {{(filterItems || navItems).length - lessShow}} 条数据</a>
        </ant-card-grid>
        <ant-card-grid v-show="(filterItems || navItems).length > lessShow && isListAll">
          <a @click.stop="scrollToTop"><up-circle-two-tone style="font-size: 18px; vertical-align: middle;" /> 返回顶部</a>
        </ant-card-grid>
      </ant-card>

      <!-- 搜索无数据 -->
      <ant-empty :image="AntEmpty.PRESENTED_IMAGE_SIMPLE" v-show="filterNoData" />

      <!-- 详情 -->
      <ant-modal
        v-if="categoryName !== 'weapon'"
        width="600px"
        :title="detailTitle"
        v-model:visible="detailShow"
        forceRender
        :footer="null">
          <detail 
            @error="onError"
            @start="onGetItem"
            @loaded="onLoaded"
            :detail-columns="detailColumns"
          />
      </ant-modal>
    </ant-spin>

  </div>
</template>

<script>
/* global VERSION */
import Detail from '../components/Detail.vue'
import AntCard from 'ant-design-vue/lib/card'
import AntCardGrid from 'ant-design-vue/lib/card/Grid'
import AntInputSearch from 'ant-design-vue/lib/input/Search'
import AntModal from 'ant-design-vue/lib/modal'
import AntSpin from 'ant-design-vue/lib/spin'
import notification from 'ant-design-vue/lib/notification'
import AntEmpty from 'ant-design-vue/lib/empty'
import UpCircleTwoTone from '@ant-design/icons-vue/UpCircleTwoTone'

const axios = require('axios')

// 缓存
let CACHE = {}

export default {
  components: {
    Detail,
    AntCard,
    AntCardGrid,
    AntInputSearch,
    AntModal,
    AntSpin,
    AntEmpty,
    UpCircleTwoTone
  },
  props: {
    lessShow: {
      type: Number,
      default: 100
    },
    hasFilter: {
      type: Boolean,
      default: false
    },
    fixedNavItems: [Object],
    tags: [Object],
    descriptions: [Object],
    detailColumns: Object
  },
  data() {
    return {
      AntEmpty,
      keyword: '',
      navItems: null,
      filterItems: null,
      detailShow: false,
      detailTitle: '',
      loading: false,
      lastPath: null,
      isListAll: false
    }
  },
  computed: {
    filterNoData() {
      return this.filterItems && this.filterItems.length === 0
    },
    categoryName() {
      return this.$route.meta.name
    }
  },
  created() {
    if (this.fixedNavItems) {
      this.navItems = this.fixedNavItems
    } else if (CACHE[this.categoryName]) {
      this.navItems = CACHE[this.categoryName]
    } else {
      this.fetch()
    }
  },
  methods: {
    fetch() {
      axios.get(`/data/mobile-style/${this.categoryName}/list.json?${VERSION}`).then(res => {
        res.data.forEach(item => {
          item.title = item.name
          item.path = `/${this.categoryName}/${item.no}`
          
          if (this.categoryName === 'monster') {
            item.icon = `/img/icon/monster/${item.no}.png`
          }
        })

        this.navItems = res.data
        CACHE[this.categoryName] = this.navItems
      })
    },
    cardClick(event) { // 用于重新打开之前关掉的 modal
      let target = event.target
      if (target.getAttribute('href') === this.lastPath && target.classList.contains('router-link-exact-active')) {
        this.detailShow = true
      }
    },
    tip(type, title, desc) {
      notification[type]({
        message: title || '提示',
        description: desc
      })
    },
    onSearch() {
      this.keyword = this.keyword.trim()

      if (this.keyword === '') {
        this.filterItems = null
        return
      }

      let tmpArr = []
      this.navItems.forEach(item => {
        if (item.title.indexOf(this.keyword) > -1) {
          tmpArr.push(JSON.parse(JSON.stringify(item)))
        }
      })
      this.filterItems = tmpArr
    },
    onGetItem() {
      this.loading = true
    },
    onLoaded(title) {
      this.lastPath = this.$route.href
      this.detailTitle = title
      
      this.$nextTick(() => {
        this.loading = false
        this.detailShow = true
      })
    },
    onError() {
      this.loading = false
      this.tip('warn', '网络出错，请重新再试')
    },
    expandAll() {
      this.isListAll = true
    },
    scrollToTop() {
      window.scrollTo({ 
        top: 0, 
        behavior: 'smooth' 
      })
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/_mixin.scss';

.sub-nav {
  overflow: auto;
  font-size: 14px;
  width: 100%;

  &.loading {
    .ant-spin-nested-loading {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .ant-card {
    width: 100%;
  }

  .ant-card-head {
    background-color: rgb(250,250,250);
  }

  .ant-card-grid {
    width: 12.5%;
    cursor: pointer;
    padding: 0;

    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
    }

    a {
      color: rgba(0,0,0,0.8);
      width: 100%;
      height: 100%;
      padding: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
    }
  }

  .expand-all {
    background-color: #eee;
  }
}

@include tablet {
  .sub-nav {
    .ant-card-grid {
      width: 25%;
    }
  }
}

@include mobile {
  .sub-nav {
    .ant-card-grid {
      width: 50%;
    }
  }
}

// 重写
.ant-card-extra {
  max-width: 70%;
}

</style>
