<template>
  <div class="home-main">
    <div class="home-nav">
      <ant-card title="导航">
        <ant-card-grid v-for="(item, i) in menu" :key="i">
          <router-link :to="'/' + item.name">
            <img v-if="item.icon" :src="item.icon" :alt="item.title" />
            {{ item.title }}
          </router-link>
        </ant-card-grid>
      </ant-card>
    </div>
  </div>
</template>

<script>
import AntCard from "ant-design-vue/lib/card";
import AntCardGrid from "ant-design-vue/lib/card/Grid";

export default {
  components: {
    AntCard,
    AntCardGrid,
  },
  data() {
    return {
      menu: [
        {
          name: "monster",
          title: "怪物",
        },
        {
          name: "weapon",
          title: "武器",
        },
        {
          name: "armor",
          title: "防具",
        },
        {
          name: "quest",
          title: "任务",
        },
        {
          name: "item",
          title: "道具",
        },
        {
          name: "skill",
          title: "技能",
        },
        {
          name: "maps",
          title: "地图",
        },
        {
          name: "donate",
          title: "捐助",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "../assets/css/_mixin.scss";

.home-main {
  text-align: center;
  padding: 30px;

  .home-nav {
    max-width: 500px;
    margin: 0 auto 50px;

    .ant-card-grid {
      padding: 0;
    }

    a {
      width: 100%;
      height: 100%;
      padding: 24px;
    }
  }

  .big-title {
    font-size: 36px;
  }

  .count-down {
    font-size: 20px;

    .days {
      font-size: 26px;
      color: $mainColor;
    }
  }

  .detail-link {
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;

    img {
      display: block;
      margin: 0 auto;
    }
  }
}

@include mobile {
  .home-main {
    .big-title {
      font-size: 20px;
    }

    .count-down {
      font-size: 16px;
    }
  }
}
</style>
