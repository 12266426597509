<template>
  <div class="sub-list" @click="detailClick($event)">
    <div class="fade"></div>

    <ant-table
      :columns="columns"
      row-key="no"
      :data-source="list"
      :loading="{spinning: loading, tip: '加载中...', delay: 100}"
      :pagination="pagination"
      :scroll="tableSize"
    >
      <!-- 下拉筛选：文本框 -->
      <template
        v-slot:filterText="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">
          <ant-input
            :ref="c => (column.searchInput = c)"
            :placeholder="getFilterText(column)"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <ant-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template v-slot:icon><search-outlined /></template>
            筛选
          </ant-button>
          <ant-button size="small" style="width: 90px;" @click="handleReset(clearFilters)">
            重置
          </ant-button>
        </div>
      </template>

      <!-- 下拉筛选：查询派生路线 -->
      <template
        v-slot:deriveFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">
          <ant-input
            :ref="c => (column.searchInput = c) && (filterTools = {selectedKeys, confirm})"
            placeholder="输入序号筛选"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="lookDerive(selectedKeys[0])"
          />
          <ant-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="lookDerive(selectedKeys[0])"
          >
            <template v-slot:icon><search-outlined /></template>
            筛选
          </ant-button>
          <ant-button size="small" style="width: 90px;" @click="handleReset(clearFilters)">
            重置
          </ant-button>
        </div>
      </template>

      <!-- 自定义渲染：派生路线 -->
      <template v-slot:derive="{ text, record }">
        <div class="derive-cell" v-if="text" @click="lookDerive(record.no)">
          <template v-for="(num, i) of text" :key="i">
            <span v-if="num === '-'"><swap-right-outlined /></span>
            <span v-else-if="num === '--'"><arrow-right-outlined /></span>
            <span v-else>{{ num }}</span>
          </template>
          <span class="self">
            {{ record.no }}
          </span>
          <search-outlined />
        </div>
        <span v-else>-</span>
      </template>

      <!-- 自定义渲染：筛选高亮 -->
      <template v-slot:highlight="{ text, column }">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-bind:key="i"
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" class="highlight">
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>

      <!-- 自定义渲染：插槽 -->
      <template v-slot:slot="{ text }">
        <template v-if="text === 3">ooo</template>
        <template v-else-if="text === 2">oo-</template>
        <template v-else-if="text === 1">o--</template>
        <template v-else>---</template>
      </template>

      <!-- 自定义渲染：斩味 -->
      <template v-slot:sharpness="{ text }">
        <div class="sharpness" v-for="(sharpnessItem, i) of text" :key="i">
          <div>
            <span v-for="(color, j) of sharpnessColors" :key="j" v-show="sharpnessItem[j]" :class="color" :style="{width: sharpnessItem[j] + 'px'}"></span>
          </div>
        </div>
      </template>

      <!-- 自定义渲染：百分比 -->
      <template v-slot:percent="{ text }">
        <span>{{ text ? (text * 100).toFixed(0) + '%' : '-'}}</span>
      </template>

      <!-- 自定义渲染：属性 -->
      <template v-slot:effect="{ text, record }">
        <span>{{ text !== '-' ? text + '+' + record.effect_num : text}}</span>
      </template>

      <!-- 自定义渲染：音符 -->
      <template v-slot:toneSymbol="{ text }">
        <span v-for="(num, i) of text" :class="['tone', 'tone-' + num]" :key="i">♪</span>
      </template>

      <!-- 自定义渲染：曲射 -->
      <template v-slot:holdShot="{ text, record }">
        <div v-for="(lv, i) of text" :key="i">{{`Lv${i + 1} ${lv}`}}</div>
        <div v-if="record.addTo"><span style="margin-right: 7px;">[+]</span>{{record.addTo}}</div>
      </template>

      <!-- 自定义渲染：任务 -->
      <template v-slot:quest="{ text }">
        {{ questTypeMap[text] }}
      </template>

      <!-- 自定义渲染：可否升级 -->
      <template v-slot:isLatest="{ text }">
        {{ text ? '否' : '是' }}
      </template>

      <!-- 自定义渲染：武器稀有图片 -->
      <template v-slot:weaponRare="{ text }">
        <img :src="`/img/icon/weapon/rare/${weaponTypeMap[type]}_${text}.png`" />
      </template>

      <!-- 自定义渲染：防具稀有图片 -->
      <template v-slot:armorRare="{ text, record }">
        <img :src="`/img/icon/armor/rare/${record.part}_${text}.png`" />
      </template>

      <!-- 自定义渲染：防具部位 -->
      <template v-slot:armorPart="{ text }">
        <img :src="armorPartMap[text]" />
      </template>

      <!-- 自定义渲染：防具类型 -->
      <template v-slot:armorType="{ text }">
        <img v-if="text > 0" :src="armorTypeMap[text]" />
        <span v-else>-</span>
      </template>

      <!-- 自定义渲染：防具性别 -->
      <template v-slot:armorGender="{ text }">
        <i class="gender male" v-if="text === 1">♂</i>
        <i class="gender female" v-else-if="text === 2">♀</i>
        <span v-else>-</span>
      </template>
      
      <!-- 自定义渲染：防具性别 -->
      <template v-slot:armorSkills="{ text }">
        <div v-for="(skill, i) in text" :key="i">{{skill.name + (skill.num > 0 ? '+' + skill.num : skill.num)}}</div>
        <span v-if="text && text.length === 0">-</span>
      </template>

      <!-- 自定义渲染：数组信息 -->
      <template v-slot:multipleLine="{ text }">
        <div v-if="text">
          <div v-for="(item, i) in text.split(',')" :key="i">{{item}}</div>
        </div>
        <span v-else>-</span>
      </template>

      <!-- 自定义渲染：兼容无 -->
      <template v-slot:fixNull="{ text }">
        <span>{{ text || '-'}}</span>
      </template>

      <!-- 自定义渲染：操作 -->
      <template v-slot:action="{ record }">
        <router-link :to="`/${categoryName}/${type ? type + '/' : ''}${record.no}`">详情</router-link>
      </template>

    </ant-table>

    <!-- 详情 -->
    <ant-modal
      width="600px"
      :title="detailTitle"
      v-model:visible="detailShow"
      forceRender
      :footer="null">
        <detail 
          @error="onError"
          @start="onGetItem"
          @loaded="onLoaded"
          :detail-columns="detailColumns"
        />
    </ant-modal>
  </div>
</template>

<script>
/* global VERSION */
import Detail from '../components/Detail.vue'
import axios from 'axios'
import AntTable from 'ant-design-vue/lib/table'
import AntInput from 'ant-design-vue/lib/input'
import AntButton from 'ant-design-vue/lib/button'
import SearchOutlined from '@ant-design/icons-vue/SearchOutlined'
import SwapRightOutlined from '@ant-design/icons-vue/SwapRightOutlined'
import ArrowRightOutlined from '@ant-design/icons-vue/ArrowRightOutlined'
import AntModal from 'ant-design-vue/lib/modal'
import notification from 'ant-design-vue/lib/notification'

// 通用工具：排序，筛选 生成器
const TOOLS = {
  isMobile: window.innerWidth <= 1000,
  filterGenerator: {
    common: function(key) {
      return function(value, record) {
        value = value.trim().toLowerCase()
        return record[key].toString().toLowerCase().indexOf(value) > -1
      }
    },
    eq: function(key) {
      return function(value, record) {
        return record[key] == value
      }
    },
    skill: function(key) {
      return function(value, record) {
        let reduce = value.indexOf('-') > -1
        let num = CACHE.skillInput[1] || 0

        return record[key].some(item => item.name === CACHE.skillInput[0] && (reduce ? item.num <= -num : item.num >= num))
      }
    }
  },
  sorterGenerator: {
    common: function(key) {
      return function(a, b) {
        return a[key] - b[key]
      }
    },
    lastNum: function(key) {
      return function(a, b) {
        return a[key].substr(-1) - b[key].substr(-1)
      }
    }
  },
  filterInputAutoFocus: function(visible) {
    visible && setTimeout(() => {
      this.searchInput.focus()
    }, 0)
  },
  deriveFilter: function(value, record) {
    if (!CACHE.deriveItems) return false
    return CACHE.deriveItems.indexOf(record.no) > -1
  },
  sharpnessFilter: function(value, record) {
    return record.sharpness && record.sharpness[0][value]
  },
  bowShotFilter: function(value, record) {
    return record.hold[record.hold.length - 1].indexOf(value) > -1
  },
  fitWidth: function (num) {
    return this.isMobile ? num : null
  },
  getTableSize: function() {
    if (this.isMobile) {
      return {x: 1366}
    } else {
      return {}
    }
  },
  cloneObject: function(obj) {
    return JSON.parse(JSON.stringify(obj))
  }
}

// 缓存
let CACHE = {
  deriveItems: null,
  listMap: {}
}

const fixColumns = function(columns, context) {
  columns.forEach(item => {

    if ('width' in item) {
      item.width = TOOLS.fitWidth(item.width)
    }

    if ('sorter' in item) {
      if (item.sorter === true) {
        item.sorter = 'common'
      }
      
      if (typeof item.sorter === 'string') {
        item.sorter = TOOLS.sorterGenerator[item.sorter](item.dataIndex)
      }
    }

    if (typeof item.customFilter === 'string') {
      item.onFilter = TOOLS.filterGenerator[item.customFilter](item.dataIndex)
      delete item.customFilter

    } else if (typeof item.onFilter === 'string') {
      item.onFilter = TOOLS[item.onFilter]
    }

    if (typeof item.onFilterDropdownVisibleChange === 'string') {
      item.onFilterDropdownVisibleChange = TOOLS[item.onFilterDropdownVisibleChange]
    }

    // 绑定上下文
    !['onFilter'].forEach(fnName => {
      if (item[fnName] instanceof Function) {
        item[fnName] = item[fnName].bind(context)
      }
    })

  })

  return columns
}

export default {
  components: {
    Detail,
    AntTable,
    AntInput,
    AntButton,
    SearchOutlined,
    SwapRightOutlined,
    ArrowRightOutlined,
    AntModal
  },
  props: {
    columnsConfig: [Object],
    detailConfig: Object
  },
  watch: {
    $route(to, from) {
      if (
        to.path === from.path || 
        to.meta.name !== from.meta.name || // 切到其他路由不做查询
        !to.params.type // 类别根目录不做查询
      ) {
        return
      }

      this.fetch()
    }
  },
  data() {
    return {
      // 表格
      list: [],
      loading: false,
      tableSize: TOOLS.getTableSize(),
      pagination: {
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        showTotal: total => `共 ${total} 条`
      },

      // 搜索
      filterTools: null,
      searchText: '',
      searchedColumn: '',

      // 详情
      detailShow: false,
      detailTitle: '',
      lastPath: null,

      // 样式
      sharpnessColors: ['red', 'orange', 'yellow', 'green', 'blue', 'white', 'purple'],

      // 数据
      questTypeMap: {
        '-1': '-',
        '1': '关键',
        '2': '紧急'
      },
      weaponTypeMap: {
        'great-sword': 1,
        'long-sword': 2,
        'sword-shield': 3,
        'dual-blades': 4,
        'hammer': 5,
        'hunting-horn': 6,
        'lance': 7,
        'gunlance': 8,
        'switch-axe': 9,
        'charge-blade': 10,
        'insect-glaive': 11,
        'light-bowgun': 12,
        'heavy-bowgun': 13, 
        'bow': 14
      },
      armorPartMap: {
        1: '/img/icon/armor/part_1.png',
        2: '/img/icon/armor/part_2.png',
        3: '/img/icon/armor/part_3.png',
        4: '/img/icon/armor/part_4.png',
        5: '/img/icon/armor/part_5.png'
      },
      armorTypeMap: {
        1: '/img/icon/weapon/1.png',
        2: '/img/icon/weapon/14.png',
      }
    }
  },
  beforeCreate() {
    let columns = TOOLS.cloneObject(this.columnsConfig)
    this.columns = fixColumns(columns, this)
    this.detailColumns = this.detailConfig
  },
  mounted() {
    this.fetch()
    TOOLS.isMobile && this.autoFade()
  },
  computed: {
    categoryName() {
      return this.$route.meta.name
    },
    type() {
      return this.$route.params.type
    }
  },
  methods: {
    autoFade() {
      let $listWraps = document.querySelectorAll('.sub-list')

      $listWraps.forEach($listNode => {
        const $fadeLayer = $listNode.querySelector('.fade')
        const node = $listNode.querySelector('.ant-table-body')

        if ($fadeLayer && !node.onscroll) {
          const showWidth = node.offsetWidth
          const totalWidth = node.scrollWidth
          const fadeWidth = window.innerWidth / 3
          $fadeLayer.style.width = fadeWidth + 'px'

          let timer
          node.onscroll = () => {
            if (timer) {
              clearTimeout(timer)
              timer = null
            }
            
            timer = setTimeout(() => {
              let scrollWidth = node.scrollLeft
              let leftWidth = totalWidth - (scrollWidth + showWidth)

              if (leftWidth < fadeWidth) {
                $fadeLayer.style.width = leftWidth + 'px'
              } else {
                $fadeLayer.style.width = fadeWidth + 'px'
              }
            }, 10)
          }
        }
      })
    },
    lookDerive(no) {
      CACHE.deriveItems = null // 查找前先重置缓存
      no = parseInt(no)

      if (isNaN(no)) {
        this.tip('error', '请输入数字')
        return false
      }

      let item = this.list[no - 1]

      if (item && item.derive) {
        let derive = item.derive.filter(num => !isNaN(num))
        CACHE.deriveItems = derive.concat(no)
      } else {
        CACHE.deriveItems = [no]
      }

      let {selectedKeys, confirm} = this.filterTools
      selectedKeys[0] = no
      this.handleSearch(selectedKeys, confirm, 'derive')
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      // console.log(selectedKeys, confirm, dataIndex)

      if (dataIndex === 'skills') {
        CACHE.skillInput = selectedKeys[0].trim().split(/[+-]/)
      }

      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    fetch() {
      let cacheKey = this.type || this.categoryName
      if (CACHE.listMap[cacheKey]) {
        this.list = CACHE.listMap[cacheKey]
        return
      }

      this.loading = true
      axios.get(`/data/mobile-style/${this.categoryName}/${this.type ? this.type + '/' : ''}list.json?${VERSION}`).then(res => {
        this.list = res.data
        CACHE.listMap[cacheKey] = this.list

        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    filterLastItems() {
      console.log(this.table.getDefaultSelection)
    },
    tip(type, title, desc) {
      notification[type]({
        message: title || '提示',
        description: desc
      })
    },
    onGetItem() {
      this.loading = true
    },
    onLoaded(title) {
      this.lastPath = this.$route.href
      this.loading = false
      this.detailTitle = title
      this.detailShow = true
    },
    onError() {
      this.loading = false
      this.tip('warn', '网络出错，请重新再试')
    },
    detailClick(event) { // 用于重新打开之前关掉的 modal
      let target = event.target
      if (target.getAttribute('href') === this.lastPath && target.classList.contains('router-link-exact-active')) {
        this.detailShow = true
      }
    },
    getFilterText(column) {
      if (column.filterText) {
        return column.filterText
      } else if (column.dataIndex === 'no') {
        return '输入序号筛选'
      } else {
        return `输入${column.title}筛选`
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/_mixin.scss';

.sub-list {
  position: relative;

  .fade {
    @include fade;
  }

  .ant-pagination {
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-ellipsis {
        left: -0.5rem;
      }
    }
  }
}

// 斩味
.sharpness{

  > div {
    width: 100px;
    height: 8px;
    margin: 2px 0;
    background-color: #bbb;

    span {
      display: inline-block;
      vertical-align: top;
      height: 100%;
    }
  }
}
.red {
  background-color: #C00C38;
}
.orange {
  background-color: #E85018;
}
.yellow {
  background-color: #F0C830;
}
.green {
  background-color: #58D000;
}
.blue {
  background-color: #3068E8;
}
.white {
  background-color: #F0F0F0;
}
.purple {
  background-color: #c3c;
}

// 音符
.tone-skills {
  text-align: left;
  
  .tones {
    display: inline-block;
    width: 45px;
  }
}
.tone {
  font-size: 20px;
  font-style: normal;
  text-shadow: 0 0 1px #000, 0 0 1px #000;
}
.tone-1 {
  color: white;
}
.tone-2 {
  color: rgb(255, 0, 255);
}
.tone-3 {
  color: red;
}
.tone-4 {
  color: blue;
}
.tone-5 {
  color: green;
}
.tone-6 {
  color: rgb(0, 255, 255);
}
.tone-7 {
  color: yellow;
}
.tone-8 {
  color: rgb(229, 103, 23);
}


// 派生
.derive-cell {
  cursor: pointer;

  .self {
    font-weight: bold;
    margin-right: 10px;
  }
}

// 性别
.gender {
  font-style: normal;
  font-weight: bold;
  text-shadow: 0 0 1px #333, 0 0 1px #333;

  &.male {
    color: blue;
  }

  &.female {
    color: red;
  }
}

// 重写
.ant-table-filter-dropdown-btns a {
  cursor: pointer;
}

/* 移动端 */
@include mobile {
  .sub-list {
    .ant-pagination {
      text-align: center;
      float: initial;

      .ant-pagination-total-text{
        display: block;
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {

        .ant-pagination-item-ellipsis {
          letter-spacing: -5px;
          text-indent: 0;
        }
      }

    }
  }
}
</style>