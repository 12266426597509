<template>
  <nav-bar :has-filter="true" :detail-columns="detailColumns" />
</template>

<script>
import NavBar from '../components/NavBar.vue'

export default {
  components: {
    NavBar
  },
  data() {
    return {
      detailColumns: {
        baseInfo: {
          title: '基础信息',
          props: [
            {
              name: 'rare',
              title: '稀有度'
            },
            {
              name: 'qty',
              title: '可携带数量'
            }
          ]
        },
        merges: {
          title: '调和',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'qty',
              title: '数量'
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'itemA',
              title: '道具A'
            },
            {
              name: 'itemB',
              title: '道具B'
            }
          ]
        },
        monsters: {
          title: '怪物',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'rank',
              title: '位级',
              searchable: true
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'method',
              title: '获取方式'
            },
            {
              name: 'qty',
              title: '数量'
            }
          ]
        },
        maps: {
          title: '地图',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'location',
              title: '名称'
            },
            {
              name: 'rank',
              title: '位级',
              searchable: true
            },
            {
              name: 'area',
              title: '区域',
              searchable: true
            },
            {
              name: 'method',
              title: '获取方式'
            }
          ]
        },
        quests: {
          title: '任务',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'lv',
              title: '位级',
              searchable: true
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'reward',
              title: '报酬'
            },
            {
              name: 'qty',
              title: '数量'
            }
          ]
        },
        huntersForHires: {
          title: '游荡猎人',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'lv',
              title: '位级'
            },
            {
              name: 'qty',
              title: '数量'
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'reward',
              title: '报酬'
            }
          ]
        },
        battles: {
          title: '斗技大会',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'qty',
              title: '数量'
            }
          ]
        },
        catTeams: {
          title: '猛喵队',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'rank',
              title: '位级',
              searchable: true
            },
            {
              name: 'name',
              title: '地带',
              searchable: true
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'qty',
              title: '数量'
            }
          ]
        },
        grandpas: {
          title: '山菜爷爷',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'giveOut',
              title: '给出的道具'
            },
            {
              name: 'location',
              title: '地图'
            }
          ]
        }
      }
    }
  }
}
</script>
