import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import setFilters from '../filters'

import App from '../components/App.vue'
import Home from '../components/Home.vue'
import NotFound from '../components/NotFound.vue'

import Weapon from '../components/Weapon.vue'
import Armor from '../components/Armor.vue'
import Monster from '../components/Monster.vue'
import Item from '../components/Item.vue'
import Skill from '../components/Skill.vue'
import Quest from '../components/Quest.vue'
import Maps from '../components/Maps.vue'
import Donate from '../components/Donate.vue'

const routerHistory = createWebHistory()
const router = createRouter({
  linkActiveClass: 'active',
  history: routerHistory,
  routes: [
    { path: '/', component: Home, meta: {name: ''} },
    { 
      path: '/weapon/:type?/:no?',
      component: Weapon,
      name: 'weapon',
      meta: {
        name: 'weapon',
        title: '武器'
      }
    },
    { 
      path: '/armor/:no?',
      component: Armor,
      name: 'armor',
      meta: {
        name: 'armor',
        title: '防具'
      }
    },
    { 
      path: '/monster/:no?',
      component: Monster,
      name: 'monster',
      meta: {
        name: 'monster',
        title: '怪物'
      }
    },
    { 
      path: '/item/:no?',
      component: Item,
      name: 'item',
      meta: {
        name: 'item',
        title: '道具'
      }
    },
    { 
      path: '/maps/:no?',
      component: Maps,
      name: 'maps',
      meta: {
        name: 'maps',
        title: '地图'
      },
    },
    { 
      path: '/donate',
      component: Donate,
      name: 'donate',
      meta: {
        name: 'donate',
        title: '捐助'
      },
    },
    { 
      path: '/skill/:no?',
      component: Skill,
      name: 'skill',
      meta: {
        name: 'skill',
        title: '技能'
      },
    },
    { 
      path: '/quest/:no?',
      component: Quest,
      name: 'quest',
      meta: {
        name: 'quest',
        title: '任务'
      },
      // children: [{
      //   path: ':no',
      //   component: Detail
      // }]
    },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound }
  ]
})

const app = createApp(App)
setFilters(app)
app.use(router)
app.mount('#app')

