<template>
  <div>
    <ant-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
    >
      <template #tags="{ text: tags }">
        <span>
          <ant-tag
            v-for="tag in tags"
            :key="tag"
            :color="tag === '微信' ? 'green' : 'geekblue'"
          >
            {{ tag }}
          </ant-tag>
        </span>
      </template>
    </ant-table>
    <div class="donate-methods">
      <h1>捐助方式</h1>
      <div class="pay">
        <img alt="Wechat Pay" src="../assets/img/wechat_pay.jpg" />
        <img alt="Ali Pay" src="../assets/img/ali_pay.jpg" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AntTable from "ant-design-vue/lib/table";
import AntTag from "ant-design-vue/lib/tag";

const columns = [
  {
    dataIndex: "name",
    key: "name",
    title: "捐助者",
  },
  {
    title: "日期",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "平台",
    key: "tags",
    dataIndex: "tags",
    slots: { customRender: "tags" },
  },
  {
    title: "金额",
    key: "money",
    dataIndex: "money",
  },
];

const data = [
  {
    name: "**飞",
    date: "2024-10-19",
    tags: ["支付宝"],
    money: "￥ 10.00"
  },
  {
    name: "**明",
    date: "2023-10-28",
    tags: ["支付宝"],
    money: "￥ 5.00"
  },
  {
    name: "*奕",
    date: "2023-8-22",
    tags: ["支付宝"],
    money: "￥ 10.00",
  },
  {
    name: "*S",
    date: "2023-3-9",
    tags: ["微信"],
    money: "￥ 10.00",
  },
  {
    name: "*鳅",
    date: "2022-11-6",
    tags: ["微信"],
    money: "￥ 10.00",
  },
  {
    name: "*鸣",
    date: "2022-11-2",
    tags: ["支付宝"],
    money: "￥ 10.00",
  },
  {
    name: "*明",
    date: "2022-11-1",
    tags: ["微信"],
    money: "￥ 10.00",
  },
  {
    name: "*明",
    date: "2022-10-31",
    tags: ["微信"],
    money: "￥ 10.00",
  },
];

export default {
  components: {
    AntTable,
    AntTag,
  },
  data() {
    return {
      data,
      columns,
    };
  },
};
</script>

<style lang="scss">
@import "../assets/css/_mixin.scss";

.ant-tag {
  box-sizing: border-box;
  margin: 0 8px 0 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: tnum;
  display: inline-block;
  height: auto;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.donate-methods {
  text-align: center;
  margin-top: 20px;

  .pay {
    display: flex;
    width: 800px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;

    img {
      max-width: 300px;
    }
  }
}

@include mobile {
  .donate-methods {
    .pay {
      width: 90%;

      img {
        max-width: 100%;
      }
    }
  }
}
</style>
