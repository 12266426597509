<template>
  <div v-if="data" class="item-detail">
    <div v-if="categoryName === 'maps'" class="maps">
      <img :alt="data.name" :src="`/img/maps/${data.no}.png`" />
    </div>

    <ant-tabs v-model:activeKey="defaultActiveKey">
      <ant-tab-pane
        v-for="(listObj, key) in detailColumns"
        :disabled="data[key] && data[key].length === 0"
        :class="['type-' + key]"
        :key="key"
        :tab="listObj.title">
        <div class="list-wrap" v-if="data[key] && data[key].length >= 0">
          <table>
            <thead>
              <tr>
                <th v-for="(prop, p) in listObj.props" v-bind:key="p">
                  {{prop.title}}

                  <!-- 筛选 -->
                  <div :class="['search', {on: prop._searching}]" v-if="prop.searchable">
                    <filter-filled
                      :class="{filtered: !prop._searching && filterFlag[key][prop.name]}"
                      @click="switchSearch(prop)"
                    />
                    <div :class="['ant-table-filter-dropdown', 'ant-dropdown-content', {on: prop._searching}]">
                      <div style="padding: 8px;">
                        <ant-input
                          v-model:value="filterKeyword[key][prop.name]"
                          :placeholder="`输入${prop.searchType === 'num' ? '数字' : prop.title}筛选`"
                          :ref="c => (prop._refForSearchInput = c)"
                          @blur="switchSearch(prop, false)"
                          @pressEnter="onSearch(key, prop, {isPressEnter: true})"
                          style="width: 188px; margin-bottom: 8px; display: block;">
                        </ant-input>
                        <ant-button size="small" @click="onSearch(key, prop)" type="primary" style="width: 90px; margin-right: 8px;"><search-outlined /> 筛选</ant-button>
                        <ant-button size="small" @click="onSearch(key, prop, {isReset: true})" style="width: 90px;">重置</ant-button>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-show="filterList[key] ? filterList[key].length : true">
              <tr v-show="filterList[key] || i < lessShow" v-for="(item, i) in (filterList[key] || data[key])" v-bind:key="i">
                <td :class="[effectCls.indexOf(prop.name) > -1 && prop.name, {highlight: prop.highlightFn && prop.highlightFn(item[prop.name])}]" v-for="(prop, p) in listObj.props" v-bind:key="p">
                  <span v-if="prop.name === 'no'">{{ i + 1 }}</span>
                  <span v-else-if="prop.filterName" v-html="$filters[prop.filterName](item[prop.name], item)"></span>
                  <span v-else>{{ item[prop.name] || '-' }}</span>
                </td>
              </tr>
              <tr class="less-show-tip" v-show="!filterList[key] && data[key].length > lessShow">
                <td colspan="10">* 为了你的体验，不展示过多数据，请通过筛选查找数据</td>
              </tr>
            </tbody>
          </table>
          <ant-empty :image="AntEmpty.PRESENTED_IMAGE_SIMPLE" v-show="filterList[key] && filterList[key].length === 0" />
        </div>
        <div v-else>
          <ant-descriptions bordered :column="columnNum[key] || 1">
            <ant-descriptions-item
              v-for="(prop, p) in listObj.props"
              v-bind:key="p" 
              :label="prop.title" 
              :span="1"
            >
              <div v-if="prop.filterName" v-html="$filters[prop.filterName](data[key][prop.name], data[key])"></div>
              <span v-else>{{ data[key][prop.name] }}</span>
            </ant-descriptions-item>
          </ant-descriptions>
        </div>

        <!-- 边界模糊，示意右滑有内容 -->
        <div class="fade"></div>
      </ant-tab-pane>
    </ant-tabs>
  </div>
</template>

<script>
/* global VERSION */
import AntInput from 'ant-design-vue/lib/input'
import AntButton from 'ant-design-vue/lib/button'
import AntTabs, {TabPane as AntTabPane} from 'ant-design-vue/lib/tabs'
import FilterFilled from '@ant-design/icons-vue/FilterFilled'
import SearchOutlined from '@ant-design/icons-vue/SearchOutlined'
import AntEmpty from 'ant-design-vue/lib/empty'
import AntDescriptions, {DescriptionsItem as AntDescriptionsItem} from 'ant-design-vue/lib/descriptions'

const axios = require('axios')
axios.defaults.timeout = 30000

const isMobile = window.innerWidth <= 1000

export default {
  components: {
    AntInput,
    AntButton,
    FilterFilled,
    SearchOutlined,
    AntTabs,
    AntTabPane,
    AntEmpty,
    AntDescriptions,
    AntDescriptionsItem
  },
  props: {
    tags: [Object],
    descriptions: [Object],
    detailColumns: Object,
    lessShow: {
      type: Number,
      default: 20
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.path === from.path ||
        to.meta.name !== from.meta.name || // 菜单切换
        to.path === '/' + from.meta.name // 重新打开分类首页
        ) {
        return
      }

      this.fetch()
    }
  },
  data() {
    return {
      AntEmpty,
      data: null,
      filterKeyword: {},
      filterList: {},
      filterFlag: {},
      effectCls: ['water', 'fire', 'thunder', 'ice', 'dragon'],
      defaultActiveKey: 0,
      columnNum: isMobile ? {} : {
        produce: 2,
        total: 2
      }
    }
  },
  mounted() {
    this.fetch()
    this.initFilterKeyword()
  },
  computed: {
    categoryName() {
      return this.$route.meta.name
    },
    type() {
      return this.$route.params.type
    }
  },
  methods: {
    checkTabData() {
      for (let key in this.detailColumns) {
        if (this.data[key] instanceof Array) {
          if (this.data[key].length > 0) {
            this.defaultActiveKey = key
            break;
          }
        } else {
          this.defaultActiveKey = key
          break;
        }
      }
    },
    initFilterKeyword() {
      for (let key in this.detailColumns) {
        this.filterKeyword[key] = {}
        this.filterFlag[key] = {}
      }
    },
    resetFilterData() {  // 重置筛选结果和选项
      this.filterList = {}
      this.initFilterKeyword()
    },
    fetch() {
      let no = this.$route.params.no
      if (!no) return

      let type = this.type ? this.type + '/' : ''
      this.resetFilterData()

      this.$emit('start')
      axios.get(`/data/mobile-style/${this.categoryName}/${type}detail/${no}.json?${VERSION}`).then(res => {
        this.data = res.data
        this.checkTabData()
        this.$emit('loaded', this.data.name)
      }, () => {
        this.$emit('error')
      })
    },
    switchSearch(prop, doing) {

      if (doing === false) {
        setTimeout(() => {
          prop._searching = false
        }, 100)
      } else {
        prop._searching = typeof doing === 'boolean' ? doing : !prop._searching

        if (prop._searching) {
          this.$nextTick(() => {
            prop._refForSearchInput.focus()
          })
        }
      }
    },
    onSearch(listKey, prop, option = {}) {
      this.filterFlag[listKey][prop.name] = true

      if (this.filterKeyword[listKey][prop.name]) {
        this.filterKeyword[listKey][prop.name] = this.filterKeyword[listKey][prop.name].trim()
      }

      if (option.isReset || !this.filterKeyword[listKey][prop.name]) {
        delete this.filterKeyword[listKey][prop.name]
        delete this.filterFlag[listKey][prop.name]
      }

      this.filterList[listKey] = this.data[listKey].filter(item => {
        for (let propName in this.filterKeyword[listKey]) {
          let isMatch
          if (prop.searchType === 'num') {
            isMatch = item[propName] === +this.filterKeyword[listKey][propName]
          } else {
            isMatch = item[propName].indexOf(this.filterKeyword[listKey][propName]) > -1
          }

          if (!isMatch) return false
        }
        return true
      })

      if (option.isPressEnter) {
        prop._refForSearchInput.blur()
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/_mixin.scss';

.item-detail {

  .maps {
    height: 128px;
    text-align: center;
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }

  .list-wrap {
    overflow: auto;
  }

  table {
    min-height: 135px;
    width: 100%;
    letter-spacing: 0;

    thead {
      background-color: #fafafa;
    }

    th {
      position: relative;

      .search {
        position: absolute;
        z-index: 100;
        cursor: pointer;
        right: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        cursor: pointer;
        transition: background-color ease-in-out 0.3s;

        &.on {
          background-color: #e5e5e5;

          svg {
            color: rgba(0,0,0,0.45);
          }
        }

        .anticon-filter {
          padding: 14px 6px;
        }

        .anticon-search {
          svg {
            color: #fff;
          }
        }

        svg {
          color: #bfbfbf;
        }

        .filtered svg {
          color: #1890ff;
        }
      }

      .ant-table-filter-dropdown {
        position: absolute;
        top: 115%;
        right: 0;
        overflow: hidden;
        max-height: 0;
        transition: max-height ease-in-out 0.3s;

        &.on {
          max-height: 300px;
        }
      }

      &:nth-child(2) {
        .ant-table-filter-dropdown {
          right: auto;
          left: -300%;
        }
      }
    }

    th, td {
      text-align: left;
      padding: 10px;
    }

    .less-show-tip {
      font-size: 12px;
      color: #ccc;
    }
  }

  .highlight {
    font-weight: bold;
    background-color: #eee;

    &.fire {
      background-color: $fire;
    }
    &.water {
      background-color: $water;
    }
    &.thunder {
      background-color: $thunder;
    }
    &.ice {
      background-color: $ice;
    }
    &.dragon {
      background-color: $dragon;
    }
  }

  .desc {
    margin-top: 20px;
    
    td {
      text-align: left;

      &:first-child {
        background-color: rgba(0,0,0,0.2);
        font-weight: bold;
      }
    }
  }

  .ant-descriptions-view {
    border: 0 none;
  }

  .ant-tabs-tabpane {
    position: relative;

    .fade {
      @include fade;
      display: none;
    }
  }
}

@include mobile {
  .item-detail {
    .type-armors,
    .type-monsters,
    .type-maps,
    .type-quests,
    .type-huntersForHires,
    .type-catTeams,
    .type-loadItems,
    .type-items,
    .type-weaks {
      table {
        min-width: 510px;
      }

      .fade {
        display: block;
      }
    }

    .type-armors {
      table {
        min-width: 650px;
      }
    }
  }
}
</style>
