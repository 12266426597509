<template>
  <sub-list :columns-config="columns" :detail-config="detailColumns"  />
</template>

<script>
const cloneObject = function(obj) {
  return JSON.parse(JSON.stringify(obj))
}

const columns = [
  {
    dataIndex: 'no',
    title: '#',
    width: 80,
    sorter: true,
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'eq',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus',
    fixed: 'left'
  },
  {
    dataIndex: 'name',
    title: '名称',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'rank',
    title: '位级',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'type',
    title: '类型',
    filters: [
      { text: '采集', value: '采集' },
      { text: '讨伐', value: '讨伐' },
      { text: '狩猎', value: '狩猎' },
      { text: '捕获', value: '捕获' },
      // { text: '特殊', value: '特殊' },
      { text: '连续狩猎', value: '连续狩猎' },
      { text: '大连续狩猎', value: '大连续狩猎' },
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'key',
    title: '关键',
    slots: {
      customRender: 'quest'
    },
    filterMultiple: false,
    filters: [
      { text: '普通', value: '-1' },
      { text: '关键', value: '1' },
      { text: '紧急', value: '2' }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'location',
    title: '地图',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'targets',
    title: '目标',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'multipleLine'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'rush',
    title: '乱入',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'multipleLine'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    name: 'action',
    title: '操作',
    width: 60,
    slots: { customRender: 'action' }
  }
]

const rewardProps = [
  {
    name: 'no',
    title: '#'
  },
  {
    name: 'name',
    title: '道具',
    searchable: true
  },
  {
    name: 'percent',
    title: '概率',
    filterName: 'percentView'
  },
  {
    name: 'qty',
    title: '数量'
  }
]

const detailColumns = {
  goals: {
    title: '任务内容',
    props: [
      {
        name: 'major',
        title: '主任务'
      },
      {
        name: 'sub',
        title: '副任务'
      }
    ]
  },
  rewardsA: {
    title: '报酬第一行',
    props: rewardProps
  },
  rewardsB: {
    title: '报酬第二行',
    props: cloneObject(rewardProps)
  },
  rewardsD: {
    title: '报酬第三行',
    props: cloneObject(rewardProps)
  },
  rewardsC: {
    title: '副任务报酬',
    props: cloneObject(rewardProps)
  }
}

import SubList from '../components/SubList.vue'

export default {
  components: {
    SubList
  },
  data: () => {
    return {
      columns,
      detailColumns
    }
  }
}
</script>