<template>
  <div class="weapon">
    <nav-bar v-show="isCategoryRoot" :fixed-nav-items="fixedNavItems" />

    <ant-breadcrumb v-show="!isCategoryRoot">
      <ant-breadcrumb-item><router-link :to="'/' + $route.meta.name">{{$route.meta.title}}</router-link ></ant-breadcrumb-item>
      <ant-breadcrumb-item>{{subCategoryTitle}}</ant-breadcrumb-item>
    </ant-breadcrumb>

    <sub-list v-if="!isCategoryRoot" :columns-config="columns[type]" :detail-config="detailColumns[type]" />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import AntBreadcrumb from 'ant-design-vue/lib/breadcrumb'
import AntBreadcrumbItem from 'ant-design-vue/lib/breadcrumb/BreadcrumbItem'
import SubList from '../components/SubList.vue'

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj))
}

const columnFillIndex = 6

const columns = [
  {
    dataIndex: 'no',
    title: '#',
    width: 80,
    sorter: true,
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'eq',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus',
    fixed: 'left'
  },
  {
    dataIndex: 'name',
    title: '名称',
    slots: {
      filterDropdown: 'filterText',
      customRender: 'highlight'
    },
    customFilter: 'common',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'derive',
    title: '派生路线',
    slots: {
      filterDropdown: 'deriveFilterDropdown',
      customRender: 'derive'
    },
    onFilter: 'deriveFilter',
    onFilterDropdownVisibleChange: 'filterInputAutoFocus'
  },
  {
    dataIndex: 'latest',
    title: '可否升级',
    slots: {
      customRender: 'isLatest'
    },
    filterMultiple: false,
    filters: [
      { text: '不可升级', value: 1 },
      { text: '可升级', value: 0 }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'rare',
    title: '稀有',
    width: 80,
    slots: {
      customRender: 'weaponRare'
    },
    filterMultiple: false,
    filters: [
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10', value: 10 },
      { text: '11', value: 11 }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'attack',
    title: '攻击',
    width: 80,
    sorter: true
  },
  {
    dataIndex: 'sharpness',
    title: '斩味',
    width: 110,
    slots: {
      customRender: 'sharpness'
    },
    filterMultiple: false,
    filters: [
      { text: '绿斩以上', value: 3 },
      { text: '蓝斩以上', value: 4 },
      { text: '白斩以上', value: 5 },
      { text: '紫斩以上', value: 6 }
    ],
    onFilter: 'sharpnessFilter'
  },
  {
    dataIndex: 'effect',
    title: '属性',
    width: 100,
    slots: {
      customRender: 'effect'
    },
    filters: [
      { text: '水', value: '水' },
      { text: '火', value: '火' },
      { text: '雷', value: '雷' },
      { text: '冰', value: '冰' },
      { text: '龙', value: '龙' },
      { text: '毒', value: '毒' },
      { text: '麻', value: '麻' },
      { text: '眠', value: '眠' },
      { text: '爆破', value: '爆破' },
      { text: '无', value: '-' },
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'thump_num',
    title: '会心',
    width: 80,
    sorter: true,
    slots: {
      customRender: 'percent'
    }
  },
  {
    dataIndex: 'slot_num',
    title: '插槽',
    width: 100,
    sorter: true,
    slots: {
      customRender: 'slot'
    },
    filters: [
      { text: '3个', value: 3 },
      { text: '2个', value: 2 },
      { text: '1个', value: 1 },
      { text: '无', value: 0 }
    ],
    customFilter: 'eq'
  },
  {
    dataIndex: 'defense',
    title: '防御',
    width: 80,
    sorter: true,
    slots: {
      customRender: 'fixNull'
    }
  },
  {
    name: 'action',
    title: '操作',
    width: 60,
    slots: { customRender: 'action' },
    // fixed: 'right'
  }
]

const detailColumns = {
  produce: {
    title: '生产/强化',
    props: [
      {
        name: 'create',
        title: '生产材料',
        filterName: 'produceView'
      },
      {
        name: 'create_price',
        title: '生产价格',
        filterName: 'priceView'
      },
      {
        name: 'upgrade',
        title: '强化材料',
        filterName: 'produceView'
      },
      {
        name: 'upgrade_price',
        title: '强化价格',
        filterName: 'priceView'
      }
    ]
  }
}

// 列表字段扩展

// 狩猎笛
let huntingHorn = {
  columns: cloneObject(columns),
  detailColumns: {
    skills: {
      title: '笛旋律',
      props: [
        {
          name: 'no',
          title: '#',
        },
        {
          name: 'tone',
          title: '音符',
          filterName: 'toneView'
        },
        {
          name: 'title',
          title: '技能描述'
        }
      ]
    }
  }
}
huntingHorn.columns.splice(columnFillIndex, 0, {
  dataIndex: 'tone',
  title: '音符',
  width: 100,
  slots: {
    customRender: 'toneSymbol'
  }
})
huntingHorn.detailColumns.produce = cloneObject(detailColumns.produce)

// 铳枪
let gunLance = {
  columns: cloneObject(columns)
}
gunLance.columns.splice(columnFillIndex, 0, {
  dataIndex: 'holdShot',
  title: '蓄力攻击',
  filters: [
    { text: '通常', value: '通常' },
    { text: '扩散', value: '扩散' },
    { text: '放射', value: '放射' }
  ],
  customFilter: 'common',
  sorter: 'lastNum'
})

// 斩击斧
let switchAxe = {
  columns: cloneObject(columns)
}
switchAxe.columns.splice(columnFillIndex, 0, {
  dataIndex: 'bottle',
  title: '装着瓶',
  filters: [
    { text: '毒瓶', value: '毒瓶' },
    { text: '减气瓶', value: '减气瓶' },
    { text: '麻痹瓶', value: '麻痹瓶' },
    { text: '灭龙瓶', value: '灭龙瓶' },
    { text: '强击瓶', value: '强击瓶' },
    { text: '强属性瓶', value: '强属性瓶' },
  ],
  customFilter: 'eq'
})

// 充能斧
let chargeBlade = {
  columns: cloneObject(columns)
}
chargeBlade.columns.splice(columnFillIndex, 0, {
  dataIndex: 'bottle',
  title: '装着瓶',
  filterMultiple: false,
  filters: [
    { text: '榴弹瓶', value: '榴弹瓶' },
    { text: '强属性瓶', value: '强属性瓶' },
  ],
  customFilter: 'eq'
})

// 操虫棍
let insectGlaive = {
  columns: cloneObject(columns)
}
insectGlaive.columns.splice(columnFillIndex, 0, {
  dataIndex: 'type',
  title: '类型',
  filterMultiple: false,
  filters: [
    { text: '打击', value: '打击' },
    { text: '切断', value: '切断' },
  ],
  customFilter: 'eq'
})

// 轻驽/重弩
let bowGun = {
  columns: cloneObject(columns),
  detailColumns: {
    baseInfo: {
      title: '基础信息',
      props: [
        {
          name: 'loadSpeed',
          title: '装填速度'
        },
        {
          name: 'steadiness',
          title: '摇晃'
        },
        {
          name: 'recoil',
          title: '后坐力'
        }
      ]
    },
    loadItems: {
      title: '装填信息',
      props: [
        {
          name: 'no',
          title: '#'
        },
        {
          name: 'name',
          title: '名称',
          searchable: true
        },
        {
          name: 'loadQty',
          title: '装填'
        },
        {
          name: 'addToQty',
          title: '追加'
        },
        {
          name: 'max',
          title: '数量'
        },
        {
          name: 'speedShotQty',
          title: '速射'
        },
        {
          name: 'interval',
          title: '间隔'
        }
      ]
    },
    produce: cloneObject(detailColumns.produce)
  }
}
bowGun.columns.splice(columnFillIndex, 2)

// 弓
let bow = {
  columns: cloneObject(columns)
}
bow.columns.splice(columnFillIndex, 1, {
  dataIndex: 'bottles',
  title: '装着瓶',
  slots: {
    customRender: 'multipleLine'
  },
  filterMultiple: false,
  filters: [
    { text: 'LV1强击瓶', value: 'LV1强击瓶' },
    { text: 'LV2强击瓶', value: 'LV2强击瓶' },
    { text: 'LV1属性强化瓶', value: 'LV1属性强化瓶' },
    { text: 'LV2属性强化瓶', value: 'LV2属性强化瓶' },
    { text: '近战瓶', value: '近战瓶' },
    { text: '毒瓶', value: '毒瓶' },
    { text: '麻痹瓶', value: '麻痹瓶' },
    { text: '睡眠瓶', value: '睡眠瓶' },
    { text: '减气瓶', value: '减气瓶' },
    { text: '爆破瓶', value: '爆破瓶' },
    { text: '染色瓶', value: '染色瓶' },
  ],
  customFilter: 'common'

}, {
  dataIndex: 'type',
  title: '曲射',
  filterMultiple: false,
  filters: [
    { text: '爆裂型', value: '爆裂型' },
    { text: '放射型', value: '放射型' },
    { text: '集中型', value: '集中型' }
  ],
  customFilter: 'eq'
}, {
  dataIndex: 'hold',
  title: '蓄力',
  slots: {
    customRender: 'holdShot'
  },
  filterMultiple: false,
  filters: [
    { text: '连射（最后等级）', value: '连射' },
    { text: '扩散（最后等级）', value: '扩散' },
    { text: '贯穿（最后等级）', value: '贯穿' },
    { text: '重射（最后等级）', value: '重射' },
  ],
  onFilter: 'bowShotFilter'
})


export default {
  components: {
    NavBar,
    AntBreadcrumb,
    AntBreadcrumbItem,
    SubList
  },
  data: () => {
    return {
      fixedNavItems: [
        {title: '大剑', path: '/weapon/great-sword', icon: '/img/icon/weapon/1.png'},
        {title: '太刀', path: '/weapon/long-sword', icon: '/img/icon/weapon/2.png'},
        {title: '片手剑', path: '/weapon/sword-shield', icon: '/img/icon/weapon/3.png'},
        {title: '双剑', path: '/weapon/dual-blades', icon: '/img/icon/weapon/4.png'},
        {title: '锤', path: '/weapon/hammer', icon: '/img/icon/weapon/5.png'},
        {title: '狩猎笛', path: '/weapon/hunting-horn', icon: '/img/icon/weapon/6.png'},
        {title: '长枪', path: '/weapon/lance', icon: '/img/icon/weapon/7.png'},
        {title: '铳枪', path: '/weapon/gunlance', icon: '/img/icon/weapon/8.png'},
        {title: '斩击斧', path: '/weapon/switch-axe', icon: '/img/icon/weapon/9.png'},
        {title: '充能斧', path: '/weapon/charge-blade', icon: '/img/icon/weapon/10.png'},
        {title: '操虫棍', path: '/weapon/insect-glaive', icon: '/img/icon/weapon/11.png'},
        {title: '轻驽', path: '/weapon/light-bowgun', icon: '/img/icon/weapon/12.png'},
        {title: '重弩', path: '/weapon/heavy-bowgun', icon: '/img/icon/weapon/13.png'},
        {title: '弓', path: '/weapon/bow', icon: '/img/icon/weapon/14.png'}
      ],
      columns: {
        'great-sword': columns,
        'long-sword': columns,
        'sword-shield': columns,
        'dual-blades': columns,
        'hammer': columns,
        'hunting-horn': huntingHorn.columns,
        'lance': columns,
        'gunlance': gunLance.columns,
        'switch-axe': switchAxe.columns,
        'charge-blade': chargeBlade.columns,
        'insect-glaive': insectGlaive.columns,
        'light-bowgun': bowGun.columns,
        'heavy-bowgun': bowGun.columns,
        'bow': bow.columns
      },
      detailColumns: {
        'great-sword': detailColumns,
        'long-sword': detailColumns,
        'sword-shield': detailColumns,
        'dual-blades': detailColumns,
        'hammer': detailColumns,
        'hunting-horn': huntingHorn.detailColumns,
        'lance': detailColumns,
        'gunlance': detailColumns,
        'switch-axe': detailColumns,
        'charge-blade': detailColumns,
        'insect-glaive': detailColumns,
        'light-bowgun': bowGun.detailColumns,
        'heavy-bowgun': bowGun.detailColumns,
        'bow': detailColumns
      }
    }
  },
  computed: {
    categoryName() {
      return this.$route.meta.name
    },
    type() {
      return this.$route.params.type
    },
    isCategoryRoot() {
      return this.$route.path.split('/').length === 2
    },
    subCategoryTitle() {
      for (let item of this.fixedNavItems) {
        if (item.path.split('/').pop() === this.type) {
          return item.title
        }
      }

      return '未知'
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/_mixin.scss';

.weapon {
  .ant-breadcrumb {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
}

@include mobile {
  .weapon {
    .ant-breadcrumb {
      padding: 20px 0 10px 20px;
    }
  }
}
</style>