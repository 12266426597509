<template>
  <nav-bar :has-filter="true" :detail-columns="detailColumns" />
</template>

<script>
function attackHighlight(item) {
  return item >= 45
}

function specialHighlight(item) {
  return item >= 25
}

import NavBar from '../components/NavBar.vue'

export default {
  components: {
    NavBar
  },
  data() {
    return {
      detailColumns: {
        lines: {
          title: '移动路线',
          props: [
            {
              name: 'locationNo',
              title: '地图',
              filterName: 'locationImgView'
            }, {
              name: 'startArea',
              title: '出现 > 移动 > 休息',
              filterName: 'moveLinesView'
            }
          ]
        },
        items: {
          title: '道具',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'rank',
              title: '位级'
            },
            {
              name: 'percent',
              title: '概率',
              filterName: 'percentView'
            },
            {
              name: 'method',
              title: '获取方式'
            },
            {
              name: 'qty',
              title: '数量'
            }
          ]
        },
        weaks: {
          title: '肉质',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'part',
              title: '部位'
            },
            {
              name: 'partCondition',
              title: '条件'
            },
            {
              name: 'cut',
              title: '斩',
              highlightFn: attackHighlight
            },
            {
              name: 'hit',
              title: '打',
              highlightFn: attackHighlight
            },
            {
              name: 'shot',
              title: '射',
              highlightFn: attackHighlight
            },
            {
              name: 'water',
              title: '水',
              highlightFn: specialHighlight
            },
            {
              name: 'fire',
              title: '火',
              highlightFn: specialHighlight
            },
            {
              name: 'thunder',
              title: '雷',
              highlightFn: specialHighlight
            },
            {
              name: 'ice',
              title: '冰',
              highlightFn: specialHighlight
            },
            {
              name: 'dragon',
              title: '龙',
              highlightFn: specialHighlight
            }
          ]
        }
      }
    }
  }
}
</script>