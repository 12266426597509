<template>
  <nav-bar :has-filter="true" :detail-columns="detailColumns" />
</template>

<script>
import NavBar from '../components/NavBar.vue'

export default {
  components: {
    NavBar
  },
  data() {
    return {
      detailColumns: {
        baseInfo: {
          title: '技能说明',
          props: [
            {
              name: 'basename',
              title: '基础技能'
            },
            {
              name: 'num',
              title: '技能触发点数'
            },
            {
              name: 'desc',
              title: '技能描述'
            }
          ]
        },
        armors: {
          title: '防具',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'rare',
              title: '稀有'
            },
            {
              name: 'slot_num',
              title: '插槽',
              searchable: true,
              searchType: 'num'
            },
            {
              name: 'num',
              title: '技能点',
              searchable: true,
              searchType: 'num'
            },
            {
              name: 'defense',
              title: '防御'
            }
          ]
        },
        jewelry: {
          title: '装饰品',
          props: [
            {
              name: 'no',
              title: '#'
            },
            {
              name: 'name',
              title: '名称',
              searchable: true
            },
            {
              name: 'slot_num',
              title: '需插槽数',
              searchable: true,
              searchType: 'num'
            },
            {
              name: 'num',
              title: '技能点',
              searchable: true,
              searchType: 'num'
            }
          ]
        }
      }
    }
  }
}
</script>