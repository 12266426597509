export default function setFilters(app) {
  app.config.globalProperties.$filters = {

    moveLinesView(startArea, record) {
      return `${startArea} > ${record.moveArea} > ${record.restArea}`
    },

    locationImgView(locationNo) {
      return `<img src="/img/maps/${locationNo}.png" />`
    },

    armorPartView(part) {
      return `<img src="/img/icon/armor/part_${part}.png" />`
    },

    armorSetSkillView(skills) {
      let arr = []
      for (let name in skills) {
        arr.push(name + (skills[name] > 0 ? `+${skills[name]}` : skills[name]))
      }
      return arr.join('<br>')
    },

    armorSkillView(skills) {
      return skills.map(item => {
        return item.name + (item.num > 0 ? `+${item.num}` : item.num)
      }).join('<br>')
    },

    priceView(num) {
      if (num === 0) return '-'

      let tmp = ''
      num = num.toString()
      
      let len = num.length
      let i = len - 1
      let count = 0
      while (i >= 0) {
        if (count % 3 === 0 && count > 0) {
          tmp = ',' + tmp
        }

        tmp = num[i] + tmp
        count++
        i--
      }

      return tmp + ' z'
    },

    toneView(toneArr) {
        let html = ''
        toneArr.forEach(item => {
          html += `<i class="tone tone-${item}">♪</i>`
        })
        return html
    },

    produceView(materialArr) {
      if (materialArr.length === 0) return '-'

      let htmlArr = []
      materialArr.forEach(item => {
        htmlArr.push(`${item.name} x ${item.qty}`)
      })
      return htmlArr.join('<br>')
    },

    percentView(num) {
      if (num === 0) return '-'
      return (num * 100).toFixed(0) + '%'
    },

    slotView(slotNum) {
      if (slotNum === 0) {
        return '---'
      } else if (slotNum === 1) {
        return 'o--'
      } else if (slotNum === 2) {
        return 'oo-'
      } else if (slotNum === 3) {
        return 'ooo'
      } else {
        return 'Unknow'
      }
    }
  }
}