<template>
  <div class="not-found">
    &gt;_&lt; 你好像迷路了！ <a href="/">点我带你回家 >></a>
  </div>
</template>

<style lang="scss">
.not-found {
  margin-top: 30px;
  font-size: 20px;
  
  a {
    margin-top: 10px;
    color: #fff;
    text-decoration: underline;
  }
}
</style>
