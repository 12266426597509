<template>
  <nav-bar :has-filter="true" :detail-columns="detailColumns" />
</template>

<script>
import NavBar from '../components/NavBar.vue'

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj))
}

const props = [
  {
    name: 'no',
    title: '#'
  },
  {
    name: 'area',
    title: '区域',
    searchable: true
  },
  {
    name: 'name',
    title: '道具',
    searchable: true
  },
  {
    name: 'method',
    title: '获取方式',
    searchable: true
  }
]

export default {
  components: {
    NavBar
  },
  data() {
    return {
      detailColumns: {
        lowRank: {
          title: '下位',
          props: cloneObject(props)
        },
        highRank: {
          title: '上位',
          props: cloneObject(props)
        },
        greatRank: {
          title: 'G级',
          props: cloneObject(props)
        }
      }
    }
  }
}
</script>